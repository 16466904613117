import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconClock = ({
  title = 'Clock',
  color = 'brightRed',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <g fill={colors[color]}>
      <path
        d="M22.3,16.3v9.8c0,0.9,0.5,1.8,1.3,2.3l8.3,4.9c1,0.6,2.2,0.2,2.7-0.7c0.6-1,0.3-2.2-0.7-2.7l-7.7-4.6v-9.1
	c0-1.1-0.9-2-2-2S22.3,15.2,22.3,16.3z M49,18.3V4.2c0-1.2-1.4-1.8-2.3-0.9L42,8c-4.8-4.8-11.7-7.6-19.2-6.9
	c-11.2,1-20.4,10-21.6,21.2C-0.4,36.7,10.8,49,25,49c12.2,0,22.4-9.2,23.8-21c0.2-1.6-1.1-3-2.7-3c-1.3,0-2.5,1-2.6,2.3
	c-1.1,9.3-9.2,16.5-18.8,16.4c-9.9-0.1-18.2-8.5-18.4-18.4C6.2,14.9,14.6,6.3,25,6.3c5.1,0,9.8,2.1,13.2,5.5l-5.6,5.6
	c-0.9,0.9-0.3,2.3,0.9,2.3h14.1C48.4,19.7,49,19.1,49,18.3z"
      />
    </g>
  </SvgIcon>
);

export default IconClock;
