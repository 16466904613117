import * as React from 'react';
import Video from 'styleguide/components/Video/Video';
import src from 'assets/videos/design_services.mp4';
import heroImg from 'assets/images/DesignServices/design_services_hero.jpg';
import Button from 'styleguide/components/Button/Button';

const Hero = ({ setFocus }) => (
  <>
    <Video className="h-[calc(100vh-78px)] w-full object-cover" src={src} thumb={heroImg} />
    <div className="absolute top-0 mx-auto flex h-full max-w-8xl flex-col md:flex-row">
      <div
        className="mx-auto flex h-full basis-full flex-col justify-end 
self-start pb-36 pl-20 text-center md:text-left -2xl:pl-24 -lg:pl-6 -md:pl-0"
      >
        <h1 className="heading-heavy-md text-white">
          Graphic Design <br />
          Services
        </h1>
        <p
          className="text-normal paragraph-medium-desktop mx-auto my-3 text-center text-shades-50
      sm:mx-auto md:mx-0 md:max-w-[455px] md:text-left"
        >
          We’re here to help make your idea a reality - whether that means adapting your designs from the from
          the screen to the page or creating something brand new.
        </p>
        <p
          className="text-normal paragraph-medium-desktop mx-auto my-3 text-center text-shades-50
      sm:mx-auto md:mx-0 md:max-w-[455px] md:text-left"
        >
          With unlimited revisions and rush turnaround options, nobody designs for print better than
          Printivity!
        </p>
        <div className="sm:flex sm:justify-center md:justify-start">
          <Button type="button" color="blue" onClick={setFocus}>
            Learn More
          </Button>
        </div>
      </div>
    </div>
  </>
);

export default Hero;
