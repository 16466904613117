import * as React from 'react';
import cn from 'classnames';

const Video = ({ wrapperClassName = '', src, thumb, ...props }) => (
  <div className={cn('-mb-3', wrapperClassName)}>
    <video
      autoPlay
      playsInline
      muted
      src={src}
      loop
      poster={thumb}
      className={cn('w-full object-cover', props.className)}
      preload="auto"
      {...props}
    />
  </div>
);

export default Video;
