import * as React from 'react';
import cn from 'classnames';
import Image from 'styleguide/components/Image/Image';
import A from 'styleguide/components/Links/A';
import { IconArrowRight } from 'styleguide/icons';

export interface CardProps {
  className?: string;
  image: { url: string; alt: string; width: number; height: number; responsiveProportion?: number };
  title: string;
  Body: React.ReactNode;
  linkText?: string;
  linkUrl?: string;
  Bubble?: React.ReactNode;
  BubbleHtml?: string;
  onClick?: () => void;
  bubbleClassName?: string;
  titleClassName?: string;
  imageClassName?: string;
}

const BubbleOrBubbleHtml = ({
  Bubble,
  BubbleHtml,
  className,
}: {
  Bubble?: React.ReactNode;
  BubbleHtml?: string;
  className?: string;
}) => {
  const bubbleClassName = cn(
    'absolute -top-10 right-4 flex h-14 max-w-fit rounded-xl bg-blue shadow-xl',
    className,
  );

  if (Bubble) {
    return <div className={bubbleClassName}>{Bubble}</div>;
  }
  if (BubbleHtml) {
    return <div className={bubbleClassName} dangerouslySetInnerHTML={{ __html: BubbleHtml }} />;
  }

  return null;
};

const Card = ({
  image,
  title,
  Body,
  linkText,
  linkUrl,
  Bubble,
  BubbleHtml,
  onClick,
  bubbleClassName,
  titleClassName,
  imageClassName,
  ...props
}: CardProps) => (
  <div
    className={cn(
      ' flex flex-col borders-solid w-full rounded-lg border border-neutral-200 p-2 transition duration-200 hover:shadow-md',
      props.className,
    )}
    onClick={onClick}
    onKeyDown={event => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick();
      }
    }}
    role="button"
    tabIndex={0}
  >
    <Image
      className={cn(
        image.responsiveProportion && 'absolute left-0 top-0 h-full object-cover',
        'w-full rounded-lg shadow',
        imageClassName,
      )}
      alt={image.alt}
      url={image.url}
      width={image.width}
      height={image.height}
      responsiveProportion={image.responsiveProportion}
      responsive={!!image.responsiveProportion}
    />
    <div className="relative grow justify-between flex flex-col">
      <BubbleOrBubbleHtml Bubble={Bubble} className={bubbleClassName} BubbleHtml={BubbleHtml} />
      {title && <h3 className={cn('sub-heading-bold p-4 xl:text-lg', titleClassName)}>{title}</h3>}
      {Body}
      {linkText && (
        <div className="self-end mt-6 flex cursor-pointer items-center justify-end pb-4 pr-4">
          <A className="mr-1 !font-hvMedium !text-sm" underline="none" href={linkUrl} color="black">
            {linkText}
          </A>
          <IconArrowRight size="xs" />
        </div>
      )}
    </div>
  </div>
);

export default Card;
