import * as React from 'react';
import Span from 'styleguide/components/Span/Span';
import Button from 'styleguide/components/Button/Button';
import { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import { LoadableComponent } from '@loadable/component';

export interface StepProps {
  StepNumberIcon?: React.ComponentType<SvgProps> | LoadableComponent<SvgProps>;
  Icon?: React.ComponentType<SvgProps> | LoadableComponent<SvgProps>;
  title?: string;
  stepNumber?: string;
  description?: string;
}

interface InformtionWithStepsProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  heading: string;
  steps: StepProps[];
  setFocus?: () => void;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

const Heading = ({ headingLevel, children, ...props }: HeadingProps) =>
  React.createElement(headingLevel, props, children);

const TsHeading = ({ headingLevel = 'p', children, className }: HeadingProps) => (
  <Heading headingLevel={headingLevel} className={className}>
    {children}
  </Heading>
);

const Block = ({ Icon, StepNumberIcon, title, description }: StepProps) => (
  <div className="mb-10 flex items-center text-white">
    <div className="relative mr-6">
      {!!StepNumberIcon && <StepNumberIcon className="!min-h-[6rem] !min-w-[4rem]" />}
      <div className="absolute left-6 top-1 flex flex-col items-center ">
        {!!Icon && <Icon className="!min-h-[5rem] !min-w-[3rem]" />}
        {!!title && <div className="font-hvBold text-xs uppercase">{title}</div>}
      </div>
    </div>

    {!!description && <Span className="max-w-sm !text-sm">{description}</Span>}
  </div>
);

const InformationWithSteps = ({
  heading,
  steps,
  setFocus,
  headingLevel = 'h1',
}: InformtionWithStepsProps) => (
  <div className="min-h-96 flex justify-center bg-[#232526] sm:min-h-[700px] -lg:flex-col">
    <div className="flex flex-col justify-center xl:mr-16">
      <TsHeading
        headingLevel={headingLevel}
        className="heading-bold-sm flex max-w-md self-center text-white xl:max-w-md -lg:mt-12 -lg:text-center"
      >
        {heading}
      </TsHeading>
      <p
        className="text-normal paragraph-medium-mobile mx-auto my-3 max-w-[402px] text-center
            text-white md:mx-auto md:mb-8
            md:mt-4 lg:mx-0 lg:mb-6 lg:mt-5 lg:text-left"
      >
        We make it easy to help you take advantage of top-tier design and printing. Work with our dedicated
        design team to create the design you need.
      </p>
      {setFocus && (
        <div className="sm:flex sm:justify-center lg:justify-start">
          <Button className="-lg:!hidden" size="md" type="button" color="blue" onClick={setFocus}>
            Get A Quote
          </Button>
        </div>
      )}
    </div>
    <div className="flex flex-col justify-center sm:px-6 -lg:items-center">
      {steps.map(({ Icon, ...stepProps }: StepProps, index: number) => (
        <Block key={index} Icon={Icon} {...stepProps} />
      ))}
    </div>
    {setFocus && (
      <div className="flex justify-center lg:justify-start -lg:mb-12">
        <Button className="lg:!hidden" size="md" type="button" color="blue" onClick={setFocus}>
          Get A Quote
        </Button>
      </div>
    )}
  </div>
);

export default InformationWithSteps;
